<template>
    <div>
        <el-collapse>
            <el-collapse-item>
                <template slot="title">
                    <i class="header-icon el-icon-s-operation"></i>筛选
                    <span class="totalwrp">总条数:{{total}}</span>
                </template>
                <div class="dataFilterWrap">
                    <el-row :gutter="8">
                        <el-col :span="4">
                            <el-button plain round type="success" @click="showaddContent">新增</el-button>
                        </el-col>
                       <!--  <el-col :span="6">
                            <label>提现状态</label>
                            <el-select v-model="status" placeholder="请选择" clearable>
                                <el-option v-for="item in statusArr" :key="item.label" :value="item.status" :label="item.label"></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="6">
                            <label>险种</label>
                            <el-select v-model="policytype" placeholder="请选择" clearable>
                                <el-option v-for="item in typeArr" :key="item.label" :value="item.value" :label="item.label"></el-option>
                            </el-select>
                        </el-col> -->
                        <el-col :span="6">
                            <label>旅行社</label>
                            <el-select v-model="company" placeholder="请选择" clearable>
                                <el-option v-for="item in companyArr" :key="item.TravelAgency_Name" :value="item.TravelAgency_ID" :label="item.TravelAgency_Name"></el-option>
                            </el-select>
                        </el-col>
                        <!-- <el-col :span="6"><label>年份</label>
                            <el-date-picker v-model="year" type="year" placeholder="选择年份" value-format="yyyy">
                            </el-date-picker>
                        </el-col> -->
                        <el-col :span="6">
                            <el-button plain round type="primary" @click="pullCashData">确定</el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-collapse-item>
        </el-collapse>
        <el-dialog :visible.sync="addContVis" title="新增提现" width="67%" modal :close-on-click-modal="false">
            <div class="details-wrp">
                <el-row class="policyWrp">
                    <!-- <el-col :span="12">
                        <label>保险类型</label>
                        <el-select v-model="Cash_PolicyType" placeholder="请选择" @change="changType">
                            <el-option v-for="itype in typeArr" :key="itype.label" :value="itype.value" :label="itype.label"></el-option>
                        </el-select>
                    </el-col> -->
                    <el-col :span="12">
                        <label>旅行社</label>
                        <el-select v-model="Cash_Company" placeholder="请选择" @change="changType">
                            <el-option v-for="itype in companyArr" :key="itype.TravelAgency_Name" :value="itype.TravelAgency_ID" :label="itype.TravelAgency_Name"></el-option>
                        </el-select>
                    </el-col>
                    <el-col :span="12">
                        <label>当前余额</label><span>{{balance}}</span>
                        <el-button type="primary" plain round @click="checkBalance">查询</el-button>
                    </el-col>
                    <el-col :span="12">
                        <label>金额</label>
                        <el-input type="number" v-model="Cash_Money"></el-input>
                    </el-col>
                    <!-- <el-col :span="12">
                        <label>提现年份</label>
                        <el-select v-model="Cash_PolicyYear" placeholder="请选择年">
                            <el-option v-for="(item,index) in years" :key="index" :label="item" :value="item">
                            </el-option>
                        </el-select>
                    </el-col> -->
                    <el-col :span="24">
                        <label>提现说明</label>
                        <el-input type="textarea" v-model="Cash_OperateName"></el-input>
                    </el-col>
    
                </el-row>
                <div class="btm-btns">
                    <el-button plain round @click="addContVis=false">取消</el-button>
                    <el-button plain round tpye="primary" @click="askForCash">确定</el-button>
                </div>
            </div>
        </el-dialog>
        <el-table :data="cashData" stripe tooltip-effect="dark" fit>
            <el-table-column label="旅行社名" width="200" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.TravelAgency_Name}}</template>
            </el-table-column>
            <!-- <el-table-column label="险种">
                <template slot-scope="scope">{{ scope.row.Cash_PolicyName }}</template>
            </el-table-column> -->
            <el-table-column label="可提现额">
                <template slot-scope="scope">{{ scope.row.Cash_Balance }}</template>
            </el-table-column>
            <!-- <el-table-column label="提现年份">
                <template slot-scope="scope">{{ scope.row.Cash_PolicyYear}}</template>
            </el-table-column> -->
            <el-table-column label="提现说明">
                <template slot-scope="scope">{{ scope.row.Cash_OperateName}}</template>
            </el-table-column>
            <el-table-column label="提现金额">
                <template slot-scope="scope">{{ scope.row.Cash_Money }}</template>
            </el-table-column>
            <el-table-column label="发起时间" show-overflow-tooltip>
                <template slot-scope="scope">{{ scope.row.Cash_Create}}</template>
            </el-table-column>
            <el-table-column label="状态" show-overflow-tooltip>
                <template slot-scope="scope">{{ cstatu(scope.row.Cash_Status)}}</template>
            </el-table-column>
            <el-table-column label="操作" width="120">
                <template slot-scope="scope">
                    <template v-if="scope.row.Cash_Status == 0">
                        <!-- <el-button plain round type="success" size="small" @click="agreeTx(scope.row.Cash_ID)">同意</el-button> -->
                        <el-button plain round type="warning" size="small" @click="refuseTx(scope.row.Cash_ID)">作废</el-button>
                    </template>                
                    <template v-if="scope.row.Cash_Status == 1">
                        <el-popconfirm confirm-button-text="好的" cancel-button-text="再考虑" icon="el-icon-info" icon-color="red" title="确定要撤回吗？" @confirm="backTx(scope.row.Cash_ID)">
                            <el-button type="warning" round plain  size="small" slot="reference">撤回</el-button>
                        </el-popconfirm>
                    </template>
                    <!-- <template v-else-if="scope.row.Cash_Status == 3">
                        <el-button plain round type="primary" size="small" @click="finishTx(scope.row.Cash_ID)">完成转账</el-button>
                    </template> -->
                </template>
            </el-table-column>
        </el-table>
        <el-pagination background layout="prev, pager, next" :total="total" :current-page="pageindex" @current-change="setPageIndx">
        </el-pagination>
    </div>
    </template>
    
    <script>
    import {
        ajax
    } from "jquery";
    export default {
        data() {
            return {
                year: "",
                status: "",
                company: "",
                policytype: "",
                reason: "",
                balance: "",
                cashData: [],
                Cash_PolicyType: "",
                Cash_Money: 0,
                Cash_OperateName: '',
                Cash_PolicyYear: '',
                pageindex: 1,
                total: 0,
                years:[],
                Cash_Company: '',
                pagesize: 10,
                companyArr: [],
                addContVis: false,
                statusArr: [{
                        status: 0,
                        label: "未处理"
                    },
                    {
                        status: 1,
                        label: "已同意"
                    },
                    {
                        status: 2,
                        label: "已确认"
                    }, {
                        status: 3,
                        label: "已转账"
                    }, {
                        status: 4,
                        label: "已拒绝"
                    }, {
                        status: 5,
                        label: "已撤销"
                    },
                ],
                typeArr: [{
                        label: "社保",
                        value: -1
                    }, {
                        label: "学生险",
                        value: 0
                    }, {
                        label: "安康险",
                        value: 1
                    }, {
                        label: "灾害险",
                        value: 2
                    }, {
                        label: "小额险",
                        value: 3
                    }, {
                        label: "旅游险",
                        value: 4
                    },
                    {
                        label: "安居险",
                        value: 6
                    }, {
                        label: "商铺险",
                        value: 7
                    },
                    {
                        label: "借意险",
                        value: 5
                    }, {
                        label: "基础保险",
                        value: 10
                    },
                ]
            }
        },
        mounted() {
            this.getCashData()
            this.getCompany()
    
            let tempYears  = []
            this.Cash_PolicyYear = Number(new Date().getFullYear())
            for(let i = 0; i < 6; i++){
                tempYears.push(Number(new Date().getFullYear())-3+i) 
            }
    
            this.years = tempYears
    
            console.log(this.years)
    
        },
    
        methods: {
            pullCashData() {
                this.pageindex = 1
                this.getCashData()
            },
            checkBalance() {
                if (this.Cash_Company === "") {
                    this.$message("请先选择旅行社")
                    return
                }
                let temp = {
                    Cash_Money: 1000000000,
                    Cash_Agency: this.Cash_Company
                }
    
                ajax({
                        url: "/AdminTravel/CashTravel.ashx?type=insert",
                        method: "POST",
                        headers: {
                            "content-type": "application/x-www-form-urlencoded",
                        },
                        data: {
                            model: JSON.stringify(temp)
                        },
                    })
                    .then(res => {
                        console.log("余额结果：", res)
                        this.$message("查询完毕！")
                        this.balance = res.balance
                    })
    
            },
            backTx(id) {
                this.ipost(
                    "/AdminTravel/CashTravel.ashx?type=reject", {
                        id: id,
                    },
                    (res) => {
                        this.getCashData()
                        this.addContVis = false
                        this.$message("已设置撤回")
                    }
                );
            },
            refuseTx(id) {
                this.ipost(
                    "/AdminTravel/CashTravel.ashx?type=reject", {
                        id: id,
                        reason: this.reason
                    },
                    (res) => {
                        this.getCashData()
                        this.addContVis = false
                        this.$message("已设置作废")
                    }
                );
            },
            agreeTx(id) {
                this.ipost(
                    "/AdminAjax/Cash.ashx?type=agree", {
                        id: id,
                    },
                    (res) => {
                        this.getCashData()
                        this.$message("已设置同意")
                    }
                );
            },
            finishTx(id) {
                this.ipost(
                    "/AdminAjax/Cash.ashx?type=finish", {
                        id: id,
                    },
                    (res) => {
                        this.getCashData()
                        this.$message("已设置转账完成")
                    }
                );
            },
            changType(e) {
                console.log(e)
            },
            askForCash() {
                let temp = {
                    Cash_Money: this.Cash_Money,
                    Cash_OperateName: this.Cash_OperateName,
                    Cash_Agency: this.Cash_Company,
                }
                if (this.Cash_Money <= 0) {
                    this.$message("提现金额需要大于零")
                    return
                }
                if (this.Cash_Company === "") {
                    this.$message("请先选择旅行社")
                    return
                }
                this.ipost(
                    "/AdminTravel/CashTravel.ashx?type=insert", {
                        model: JSON.stringify(temp)
                    },
                    (res) => {
                        this.getCashData()
                        this.addContVis = false
                        this.$message("发起提现成功")
                    }
                );
            },
            showaddContent() {
                this.addContVis = true
            },
            downloadProof(url) {
                window.open(url)
            },
            cancleCash(item) {
                this.ipost(
                    "/AdminAjax/Cash.ashx?type=cancel", {
                        id: item.Cash_ID
                    },
                    (res) => {
                        this.getCashData()
                        this.$message("取消成功")
                    }
                );
            },
            cstatu(num) {
                switch (num) {
                    case 0:
                        return "未处理";
                        break;
                    case 1:
                        return "已同意";
                        break;
                    case 2:
                        return "已确认";
                        break;
                    case 3:
                        return "已转账";
                        break;
                    case 4:
                        return "已拒绝";
                        break;
                    case 5:
                        return "已取消";
                        break;
                }
            },
            ctype(num) {
                switch (num) {
                    case (-1):
                        return "社保";
                        break;
                    case 0:
                        return "学生险";
                        break;
                    case 1:
                        return "安康险";
                        break;
                    case 2:
                        return "灾害险";
                        break;
                    case 3:
                        return "小额险";
                        break;
                    case 4:
                        return "旅游险";
                        break;
                    case 5:
                        return "借意险";
                        break;
                    case 10:
                        return "基础保险";
                        break;
                }
            },
            setPageIndx(pgindx) {
                // console.log(e);
                this.pageindex = pgindx;
                this.getCashData();
            },
            getCompany() {
                this.ipost(
                    "/AdminTravel/TravelAgency.ashx?type=select", {
                        isdelete:0,
                        pageindex: 1,
                        pagesize: 999,
                    },
                    (res) => {
                        console.log("companyArr res:", res);
                        this.companyArr = res.list;
                    }
                );
            },
            getCashData() {
                this.ipost(
                    "/AdminTravel/CashTravel.ashx?type=select", {
                        pageindex: this.pageindex,
                        pagesize: this.pagesize,
                        agency: this.company,
                    },
                    (res) => {
                        // console.log("cashData res:", res);
                        this.cashData = res.list;
                        this.total = res.count;
                    }
                );
            },
        }
    
    }
    </script>
    
    <style scoped>
    .dataFilterWrap .el-row .el-col {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 12px;
    }
    
    .dataFilterWrap .el-row .el-col label {
        display: block;
        width: 80px;
        padding-right: 2px;
    }
    
    .dataFilterWrap button {
        margin-left: 10px;
    }
    
    .policyWrp .el-col {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 6px;
    }
    
    .el-col label {
        width: 100px;
    }
    
    .el-col .el-input,
    .el-col .el-select,
    .el-col .el-textarea {
        width: calc(100% - 100px);
    }
    
    .el-table .el-button {
        margin-right: 8px;
    }
    
    .titwrp {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 12px;
        height: 31px;
    }
    
    .titwrp span {
        font-size: 13px;
    }
    
    .btm-btns {
        text-align: center;
        padding: 20px 0 0;
    }
    </style>
    